<template>
  <div>
    <h3 ref="intro">
      Parallel Lines
    </h3>
    <p>
      Let's say you are given two lines: AB & CD. <br><br>
      If both of these lines sit on the same plane and always maintain
      a constant distance from each other, then the two lines are said to be parallel to each other.
      <br><br>
      Mathematically, two lines that are parallel to each other are denoted as: AB || CD.
      <br><br>
      Parallel lines never intersect with each other.
    </p>
    <h3 ref="trans">
      Transversal Line
    </h3>
    <p>
      Imagine a line that intersects a set of parallel lines. Such a line is called a
      transversal. <br><br>
      The angles between transversal and a set of parallel lines can be grouped into pairs
      and such pairs have special names &mdash; as we have discussed in the next section.
    </p>

    <h3 ref="angles">
      Pair of Angles
    </h3>
    <p>
      Below are the names given to pair of angles.
      <ul style="list-style-type: square;">
        <li>
          <h5>Corresponding Angles</h5>
          The pair of angles that are <i>exterior</i> (outside the parallel lines) and
          are on the same side of the transversal.
        </li>
        <br>
        <li>
          <h5>Alternate Interior Angles</h5>
          The pair of angles that are <i>interior</i> (between the parallel lines) and
          on the <i>alternate</i> side of the transversal.
        </li>
        <br>
        <li>
          <h5>Alternate Exterior Angles</h5>
          The pair of angles that are <i>exterior</i> (outside of the parallel lines) and on
          the <i>alternate</i> side of the transversal.
        </li>
        <br>
        <li>
          <h5>Consecutive Interior Angles</h5>
          The pair of angles that are <i>interior</i> (inside the parallel lines) and
          are on the same side of the transversal.
        </li>
      </ul>
    </p>
    <h3 ref="pg">
      MagicGraph | Equality of Alternate Interior Angles
    </h3>
    <p>
      In this MagicGraph, we demonstrate an important theorem related to a pair parallel lines intersected by a transversal.
      This theorem states &mdash; <i>
        If two parallel lines are cut by a transversal,
        the alternate interior angles are equal.
      </i>
      <br><br>
      The blue line is a line connecting points X and Y. Point X can be moved along x-axis only
      while point Y can be moved along y-axis only.
      <br> <br>
      The red line is a line that runs parallel to the blue line and passes through point P.
      Point P can also be moved around.
      <br> <br>
      The dotted green line is a transversal that intersects the blue and red lines at points X and P, respectively.

      The angles &alpha; and &beta; are alternate interior angles made by the transveral.
    </p>
    <ul style="list-style-type: square;">
      <li>
        <h5>Shifting the Blue Line</h5> You can shift the blue line by moving points X and Y. Note that point X can be dragged along x-axis only, while point Y can be dragged along y-axis only.
        You will observe that two alternate interior angles always remain equal to each other no matter what positions you choose for points X and Y.
      </li> <br>
      <li>
        <h5>Shifting the Red Line</h5> You can shift the red line by moving point P. You will find the two alternate angles are always equal no matter where point P is.
      </li> <br>
    </ul>
    <p> You can repeat the above steps any number of times to learn different scenarios. </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ParallelLines',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Parallel Lines';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Parallet Lines',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
