import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeErase,
    placeShuffle,
    placeTest,
    placeSeg,
    setConfig,
    placeStat
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
        var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-5, 15, 15, -5],keepaspectratio: true, axis:true, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
        brd1.options.point.showInfoBox =false;
        brd1.options.image.highlight =false;
        brd1.options.layer['image']=12;
        brd1.options.layer['point']=13;
        placeTitle(brd1, 'Parallel Lines', '(Equality of Alternate Interior Angles)');
        makeResponsive(brd1);
        placeLogo(brd1);
				var PtA =brd1.create('point', [4, 0],{name:'<b>X</b>', face:'circle',size:4, strokeColor:'black', fillColor:'yellow', label:{offset:[0, 15], anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				var PtB =brd1.create('point', [0, 4],{name:'<b>Y</b>', snapToGrid:false, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fixed:false, fillOpacity:1,label:{offset:[0, 15], anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
        var PtC =brd1.create('point', [6, 4],{name:'<b>P</b>',snapToGrid:false, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fillOpacity:1, label:{offset:[0, 15], anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
        var PtD =brd1.create('parallelpoint', [PtA, PtB, PtC], {visible:false});
////////////////////////////////////////////////////////////////////////////////
        brd1.on('move', function(){
          PtA.moveTo([PtA.X(), 0]);
          PtB.moveTo([0, PtB.Y()]);});
////////////////////////////////////////////////////////////////////////////////
        var Line1 = placeSeg(brd1, [()=>PtA.X(), ()=>PtA.Y()], [()=>PtB.X(), ()=>PtB.Y()], 4, 'blue');
        var Line2 = brd1.create('parallel', [Line1,PtC], setConfig(brd1, 4,'red'));
        var Line3 = placeSeg(brd1, [()=>PtB.X(), ()=>PtB.Y()], [()=>PtC.X(), ()=>PtC.Y()], 2, 'green');
        Line3.setAttribute({dash:1});
////////////////////////////////////////////////////////////////////////////////
        var ang1 = placeAngle(brd1, PtA, PtB, PtC, 1.5,'grey');
        ang1.setAttribute({center:{visible:true}, radiusPoint:{visible:true},anglePoint:{visible:true}});
///////////////////////////////////////////////////////
        var ang2 = placeAngle(brd1, PtB, PtC, PtD, 1.5,'grey');
        ang2.setAttribute({center:{visible:true}, radiusPoint:{visible:true},anglePoint:{visible:false}});
///////////////////////////////////////////////////////////
        var analytics =placeShuffle(brd1);
        analytics.setLabel('Tap to Shuffle')
        analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
        analytics.on('over', function () {this.label.setAttribute({visible:true});});
        analytics.on('out', function () {this.label.setAttribute({visible:false});});
        var erase = placeStat(brd1);
        erase.setLabel('Tap to Test Your Answer')
        erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
        erase.on('over', function () {this.label.setAttribute({visible:true});});
        erase.on('out', function () {this.label.setAttribute({visible:false});});
        //var moveA= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
				//var moveB= brd1.create('point', [0,0],{name:'', fillOpacity:0.25, face:'circle',size:4, strokeColor:'black', fillColor:'yellow'});
			//	var PtD =brd1.create('point', [3, 2],{name:'<b>B</b> (Drag me!)',snapToGrid:true, face:'circle',size:4, strokeColor:'black', fillColor:'yellow', fillOpacity:0.5, label:{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(10*brd1.canvasWidth/500.)}}});
				//brd1.create('text', [function(){return PtB.X()*0.5-0.5*Math.sign(PtB.X());}, function(){return PtB.Y()*0.5+0.5;}, '<b>r_1</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
				//brd1.create('text', [()=>PtC.X()*0.5+0.5*PtB.X(), ()=>PtC.Y()*0.5+0.5*PtB.Y(), '<b>r_2</b>'],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd1.canvasWidth/500.)}});
				//brd1.create('segment', [PtB, moveA], {strokeColor:'blue', dash:1});
			//	brd1.create('segment', [PtD, moveB], {strokeColor:'red', dash:1});
				//brd1.create('segment', [PtB, PtC], {visible:true, strokeColor:'blue', strokeWidth:3});
				//brd1.create('segment', [PtA, PtB], {visible:true, strokeColor:'red', strokeWidth:3});
				//brd1.create('arrow', [PtA, moveC], {strokeColor:'black', strokeWidth:3});
			//	brd1.create('text', [5, 11.5, function(){return 'OA = ('+ (PtB.X()).toFixed(2)+') <i>x&#770;</i> + (' + (PtB.Y()).toFixed(2) +') <i>y&#770;</i>'}],{color:'red', anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
				//var plus=brd1.create('image', ['/assets/addition.svg', [-5, -5.5],[0.8, 0.8]], {opacity:1, fixed:true});
			//	brd1.create('text', [5, 10., function(){return 'AB = ('+ (-PtB.X()+PtC.X()).toFixed(2) + ') <i>x&#770;</i> + (' + (-PtB.Y()+PtC.Y()).toFixed(2) +') <i>y&#770;</i>'}],{color:'blue', anchorX:'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
      //  brd1.create('text', [1, 8.5, function(){return 'OB =' }],{color:'blue', anchorX:'left', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
      //  brd1.create('text', [5, 8.5, function(){return '&nbsp;<i>x&#770;</i> + ' }],{color:'blue', anchorX:'left', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
      //  brd1.create('text', [8, 8.5, function(){return '&nbsp;<i>y&#770;</i>' }],{color:'blue', anchorX:'left', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});

        var rght = placeImage(brd1,'/assets/check.svg', 9, -4, 2, 0);
        rght.setAttribute({visible:false});
        var wrng = placeImage(brd1,'/assets/cross.svg', 9, -4, 2, 0);
        wrng.setAttribute({visible:false});
        //var xInp = placeInput(brd1, 3, 8.5, "");
      //  var yInp = placeInput(brd1, 6, 8.5, "");
        brd1.create('text', [1, 11, function(){return '&alpha; ='+ (ang1.Value()*180/Math.PI).toFixed(2) +'^o'}],{visible:true,CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});
        brd1.create('text', [6, 11, function(){return '&beta; ='+ (ang1.Value()*180/Math.PI).toFixed(2) +'^o'}],{visible:true,CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd1.canvasWidth/500.)}});

        //brd1.create('segment',[[-8, -7],[-2, -7]],{fixed:true, strokeColor:'black', strokeWidth:3});
				//var runb =function(){moveB.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
				//var runc =function(){moveA.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 500)}
				//var runa =function(){moveC.moveTo([PtB.X()+PtD.X(), PtB.Y()+PtD.Y()], 2000)}
      //  var x =0; var y=0;
        analytics.on('down', function(){
          wrng.setAttribute({visible:false});
          rght.setAttribute({visible:false});
          clearInputFields(brd1);
          x =Math.round(4*Math.random())+3;
          y =Math.round(4*Math.random())+3;
          PtB.moveTo([x, y],100);
          PtC.moveTo([x+Math.round(2*Math.random())+2, y+Math.round(4*Math.random())-2], 100);});
				//PtB.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
			  //PtD.on('drag',function(){j=0; moveC.moveTo([0,0]); moveA.moveTo([0,0]); moveB.moveTo([0,0])});
        erase.on('down', function(){
          if(xInp.Value() == PtC.X() && yInp.Value() == PtC.Y()){
            rght.setAttribute({visible:true});
            wrng.setAttribute({visible:false})
          }
          else{
            rght.setAttribute({visible:false});
            wrng.setAttribute({visible:true})
          }
        })
}}
export default Boxes;
